<template>
  <page-view>
    <section slot="content">
    <div class="mt-5">
      грузовладельцам и экспедиторам, которые хотят:
      <ul>
        <li>автоматизировать взаимодействие с перевозчиками</li>
        <li>автоматизировать взаимодействие с перевозчиками</li>
        <li>автоматизировать взаимодействие с перевозчиками</li>
        <li>автоматизировать взаимодействие с перевозчиками</li>
      </ul>
    </div>
    <div class="mt-5">
      что вы ещё получите?
      <ul>
        <li>автоматизировать взаимодействие с перевозчиками</li>
        <li>автоматизировать взаимодействие с перевозчиками</li>
        <li>автоматизировать взаимодействие с перевозчиками</li>
        <li>автоматизировать взаимодействие с перевозчиками</li>
      </ul>
    </div>
    </section>
  </page-view>
</template>

<script>

import PageView from "@/layouts/components/PageView"

export default {
  name: 'HomePage',
  components: {
    'page-view': PageView
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {

  }
}
</script>
